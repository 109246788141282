import React from 'react';
// import OmanPolice from '../Assets/images/oman-police-logo.png';
// import CouachLogo from '../Assets/images/Couach.png';
// import MinistryOfDefence from '../Assets/images/Ministry_of_Defence_India.svg-removebg-preview-removebg-preview (1).png';
// import Lurssen from '../Assets/images/Lürssen_logo.svg.png';
// import IndianNavy from '../Assets/images/Indian_Navy_crest.svg-removebg-preview (1).png';
// import RoyalSaudiBorderGuard from '../Assets/images/1024px-Royal_Saudi_Navy_Logo.svg-removebg-preview (1).png';

import OmanPolice from '../Assets/icons/oman-police.png';
import CouachLogo from '../Assets/icons/Couach.png';
import MinistryOfDefence from '../Assets/icons/Defense-minister.png';
import Lurssen from '../Assets/icons/Lurssen.png';
import IndianNavy from '../Assets/icons/Indian_Navy_crest.png';
import RoyalSaudiBorderGuard from '../Assets/icons/Royal_soudi_navy.png';

const Partners = () => {
	return (
		<div className="header-section-partners absolute bottom-1 left-0 pb-[0.5rem] sm:pb-[1rem] md:py-[2rem] min-h-[5rem] min-w-full z-[99]">
			<div className="flex flex-row lg:flex-nowrap md:flex-nowrap columns-3 sm:columns-6 lg:columns-6 md:columns-6 flex-wrap justify-center items-center mx-auto">
				<span className="h-[40px] sm:h-[56px] md:h-[70px] w-[25%] sm:w-auto mx-2 sm:mx-4 md:mx-4 flex items-center justify-center my-2 md:my-0 lg:px-2">
					<img id="l-img" 
						data-src="" 
						src={OmanPolice}
						className="h-full block" 
						alt="United Nations Logo" 
						title="United Nations Logo" />
				</span>
				<span className="h-[40px] sm:h-[56px] md:h-[70px] w-[25%] sm:w-auto mx-2 sm:mx-4 md:mx-4 flex items-center justify-center my-2 md:my-0 lg:px-2">
					<img id="l-img" 
						data-src="" 
						src={CouachLogo} 
						className="h-full block" 
						alt="Bill &amp; Melinda Gates Foundation Logo" 
						title="Bill &amp; Melinda Gates Foundation Logo" />
				</span>
				<span className="h-[40px] sm:h-[56px] md:h-[70px] w-[25%] sm:w-auto mx-2 sm:mx-4 md:mx-4 flex items-center justify-center my-2 md:my-0 lg:px-2">
					<img id="l-img" 
						data-src="" 
						src={MinistryOfDefence} 
						className="h-full block" 
						alt="Government of India Logo" 
						title="Government of India Logo" />
				</span>
				<span className="h-[40px] sm:h-[56px] md:h-[70px] w-[25%] sm:w-auto mx-2 sm:mx-4 md:mx-4 flex items-center justify-center my-2 md:my-0 lg:px-2">
					<img id="l-img" 
						data-src="" 
						src={Lurssen} 
						className="h-full block" 
						alt="McKinsey Logo" 
						title="McKinsey Logo" />
				</span>
				<span className="h-[40px] sm:h-[56px] md:h-[70px] w-[25%] sm:w-auto mx-2 sm:mx-4 md:mx-4 flex items-center justify-center my-2 md:my-0 lg:px-2">
					<img id="l-img" 
						data-src="" 
						src={IndianNavy}
						className="h-full block" 
						alt="Unilever Logo" 
						title="Unilever Logo" />
				</span>
				<span className="h-[40px] sm:h-[56px] md:h-[70px] w-[25%] sm:w-auto mx-2 sm:mx-4 md:mx-4 flex items-center justify-center my-2 md:my-0 lg:px-2">
					<img id="l-img" 
						data-src=""
						src={RoyalSaudiBorderGuard}
						className="h-full block" 
						alt="BASF Logo" 
						title="BASF Logo" />
				</span>
			</div>
		</div>
	)
}

export default Partners;