import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Partners from '../SubComponents/Partners';
import HeaderBgVideo from '../SubComponents/HeaderBgVideo';
import { Link } from 'react-scroll';

const Header = () => {
	return (
		<div name='home' className='header-section text-white h-screen min-h-[720px] flex justify-center items-center relative'>
			<HeaderBgVideo />
			<div className="home-landing-section z-[999] !max-w-[90%] !sm:max-w-[80%] !md:max-w-[75%] lg:w-[70%] md:w-[90%] max-h-max text-center sm:text-center padding-lg">
				<p className="text-yellow font-bold text-[1rem] leading-normal sm:leading-relaxed sm:text-[1.3rem] mb-[20px] sm:mb-[20px] uppercase">Solving world’s toughest challenges with Deep Tech!</p>
				<h1 id="landing-text" className="text-light font-bold text-shadow-title lg:text-[2.5rem] mb-[20px] sm:mb-[30px] md:text-[2rem] md:font-medium text-[1.4rem] leading-normal sm:leading-tight">
					Our SaaS Platform is industry agnostic and purpose-built, driving efficiency by removing process leakages and wastage reduction.
				</h1>
				<p className="container-sm mx-auto lg:max-w-[80%] leading-6 mb-[60px] text-[16px] sm:text-[18px]">
					For almost half a decade, we have built tools that intelligently automate workflows, prioritizing criticality and removing all the chaos, achieving an unthinkable level of operating margins.
				</p>
				<Link 
					className="link-scroll-down home-page-scroll-arrow max-w-[50px] max-h-[50px] bg-[#393736] cursor-pointer p-2 pb-3 rounded-full lg:inline md:inline hidden"
					role="button" to="about-section-1" smooth={true} duration={400}
				>
					<ArrowDownwardIcon />
				</Link>
			</div>
			<Partners />
		</div>
	)
}

export default Header