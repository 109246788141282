import React from 'react';
import CustomForm from '../SubComponents/CustomForm';
import { Paper } from '@mui/material';

const Contact = () => {

	return (
		<div name='contact' className='min-h-[60vh] md:pt-8 flex flex-wrap flex-col-reverse sm:flex-col-reverse md:flex-row md:flex-nowrap justify-between items-center w-full text-[#4e4b4a] border-b-[2px] border-solid border-[#efedec]'>
			<div className='relative w-full h-auto pb-[33%] pt-5 min-h-[660px]'>
				<iframe 
					title='equitrax-on-map'
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d877.3352761191702!2d77.06992336960332!3d28.408962598486738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18e85bdf53b1%3A0xf3b3838d9b6584a!2sEQUITRAX%20CORPORATE%20VENTURES!5e0!3m2!1sen!2sin!4v1702452512176!5m2!1sen!2sin" 
					width="600" 
					height="460" 
					allowFullScreen="" 
					loading="lazy" 
					referrerPolicy="no-referrer-when-downgrade"
					className='absolute top-0 left-0 w-full h-full'
					>
				</iframe>
			</div>
			<Paper className='w-full flex items-end justify-center h-[660px] flex-col' elevation={0}>
				<CustomForm />
			</Paper>
		</div>
	)
}

export default Contact;