import React from 'react'

const Achievement = () => {
	return (
		<div className='min-h-[60vh] flex flex-col justify-center items-center container py-[4rem] px-[1rem] sm:py-[6rem] sm:px-[4rem] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
			<h1 className='text-[1.4rem] md:text-[1.5rem] lg:text-[2rem] text-left font-semibold max-w-[100%] lg:max-w-[75%] md:max-w-[90%] sm:text-center mb-[30px]'>
				In 2020, while the world plunged into a pandemic, we realised that our <span className='font-semibold italic text-[#8f8c8b]'>Deep Tech</span> platform was aiding in enormous changes.
			</h1>
			<div className='sm:text-center w-full'>
				<p className='inline-block text-[18px] sm:text-[20px] text-left sm:mx-0'>Teams were achieving</p>
				<div className='block sm:inline-block text-left'>
					<h1 className='inline-block text-[52px] sm:text-[60px] md:text-[72px] border-b-[8px] border-solid border-[#fece46] font-extrabold mr-4 sm:mx-4 leading-tight'>2x</h1>
					<p className='inline-block text-[18px] sm:text-[20px]'>the quality at</p>
				</div>
				<div className='block sm:inline-block text-left'>
					<h1 className='inline-block text-[52px] sm:text-[60px] md:text-[72px] border-b-[8px] border-solid border-[#fece46] font-extrabold mr-4 sm:mx-4 leading-tight'>50%</h1>
					<p className='inline-block text-[18px] sm:text-[20px]'>of the time, with</p>
				</div>
				<div className='block sm:inline-block text-left'>
					<h1 className='inline-block text-[52px] sm:text-[60px] md:text-[72px] border-b-[8px] border-solid border-[#fece46] font-extrabold mr-4 sm:mx-4 leading-tight'>1/3</h1>
					<p className='inline-block text-[18px] sm:text-[20px]'>of their resources.</p>
				</div>
			</div>
		</div>
	)
}

export default Achievement;