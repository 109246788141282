import React from 'react';
import BrainStorming from '../Assets/images/brainStorming.jpg';
import Business from '../Assets/images/Business.png';
import ImageMagnifier from '../SubComponents/ImageMagnifier';

const AboutSection3 = () => {

	return (
		<div className='min-h-[60vh] flex flex-col lg:flex-nowrap justify-between items-center container py-[4rem] px-[1rem] sm:py-[6rem] sm:px-[1rem] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
			<div className='lg:px-[1rem] py-[2rem] md:py-[1rem] lg:py-[0rem] w-full'>
				<p className='leading-normal mb-0 md:pb-3 text-[16px] sm:text-[18px]'>
					Innovation for us is a continuous journey, learning each day with each project that continuously throws challenges at us. As we keep building tools and technology, we keep pushing ourselves to be more agile and productive.   
				</p>
				<p className='leading-normal mb-0 md:pb-3 text-[16px] sm:text-[18px]'>
					In today's ultra-competitive environment companies make millions of decisions every day. They either prosper or decline based on the timeliness and quality of their decisions. ERPs and legacy systems generate petabytes of data causing clutter and complexity, hindering efficient and timely decisions.
				</p>
				<p className='leading-normal mb-0 md:pb-3 text-[16px] sm:text-[18px]'>
					We have built intelligence that automates processes and helps cut through the clutter aiding timely and efficient decisions, optimising processes, and removing revenue leakages. These positively impact optimising costs and growth of operating margins.
				</p>
			</div>
			<div className='lg:px-[1rem] flex-wrap md:flex-nowrap w-full flex items-center justify-around mt-4'>
				<div className='w-full md:w-[40%]'>
					<img src={BrainStorming} alt="" title="" className="h-full w-full block"/>
				</div>
				<div  className='w-full md:w-[60%] mt-4 md:mt-0 md:ml-8'>
					{/* <img src={Business} alt="" title="" className="h-full w-full block"/> */}
					<ImageMagnifier
						// width={"200px"}
						src={Business}
					/>
				</div>
			</div>
		</div>
	)
}

export default AboutSection3;