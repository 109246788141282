import React, { Component, useState } from 'react';

function ImageMagnifier({
			src,
			width,
			height,
			magnifierHeight = 160,
			magnifieWidth = 160,
			zoomLevel = 2
		}) {

		const [showMagnifier, setShowMagnifier] = useState(false);
		const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
		const [[x, y], setXY] = useState([0, 0]);

		return (
		// the container
		<div
			style={{
			position: "relative",
			height: height,
			width: width
			}}
		>
			<img
			src={src}
			style={{ height: height, width: width }}
			onMouseEnter={(e) => {
				// update image size and turn-on magnifier
				const elem = e.currentTarget;
				const { width, height } = elem.getBoundingClientRect();
				setSize([width, height]);
				setShowMagnifier(true);
			}}
			onMouseLeave={() => {
				setShowMagnifier(false);
			}}
			onMouseMove={(e) => {
				// update cursor position
				const elem = e.currentTarget;
				const { top, left } = elem.getBoundingClientRect();

				// calculate cursor position on the image
				const x = e.pageX - left - window.pageXOffset;
				const y = e.pageY - top - window.pageYOffset;
				setXY([x, y]);
			}}
			alt={"img"}
			/>
	
			<div
				style={{
					display: showMagnifier ? "" : "none",
					position: "absolute",

					// prevent magnifier blocks the mousemove event of img
					pointerEvents: "none",
					// set size of magnifier
					height: `${magnifierHeight}px`,
					width: `${magnifieWidth}px`,
					// move element center to cursor pos
					top: `${y - magnifierHeight / 2}px`,
					left: `${x - magnifieWidth / 2}px`,
					borderRadius: '50%',
					opacity: "1", // reduce opacity so you can verify position
					border: "1px solid lightgray", // show the border of magnifier
					backgroundColor: "white",
					backgroundImage: `url('${src}')`,
					backgroundRepeat: "no-repeat",
					//calculate zoomed image size
    				backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
					//calculate position of zoomed image.
    				backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
    				backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
				}}
			>

			</div>
		</div>
		)
}

export default ImageMagnifier;