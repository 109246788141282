import React from 'react';
import SectorTabs from '../SubComponents/SectorTabs';


const Sectors = () => {
	return (
		<div className='min-h-[60vh] flex flex-wrap flex-col md:flex-nowrap justify-between items-center container py-[4rem] px-[1rem] sm:py-[6rem] sm:px-[1rem] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
			<h1 className='text-[1.4rem] md:text-[1.5rem] lg:text-[2rem] font-semibold mb-5 text-left sm:text-center'>
				Helping create a sustainable world through cost wastage reduction
			</h1>
			<SectorTabs />
		</div>
	)
}

export default Sectors;