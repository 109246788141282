import React, { useState } from 'react';
import EnergyProductionAndDistribution from '../Assets/images/ENERGY PRODUCTION AND DISTRIBUTION.png';
import InfrastructureManagement from '../Assets/images/INFRASTRUCTURE MANAGEMENT.png';
import Logistics from '../Assets/images/LOGISTICS.png';
import ShipBuilding from '../Assets/images/SHIP BUILDING.png';
import SupplyChainManagement from '../Assets/images/SUPPLY CHAIN MANAGEMENT.png';

const SectorTabs = () => {

	const [clicked, setClicked] = useState("0");

	let titles, images;

	const sectors = [
		titles = [
			{
				color: '#26800a',
				title: 'Product in use with clients',
			},
			{
				color: '#ff8c00',
				title: 'Use Cases / POCs in pipeline',
			},
			{
				color: '#161dc3',
				title: 'Future product roadmap',
			}
		],
		images = [
			{
				images: ShipBuilding
			},
			{
				images: SupplyChainManagement
			},
			{
				images: Logistics
			},
			{
				images: EnergyProductionAndDistribution
			},
			{
				images: InfrastructureManagement
			}
		]
	]

	return (
		<div className='w-full flex flex-wrap flex-col lg:flex-nowrap md:mt-8 justify-between items-end'>
			<div className='w-full flex items-center justify-center'>
				<ul className="w-full my-8 flex flex-wrap justify-center xl:justify-between items-center">
					{sectors[1].map((img, index) => {
							return (
								<li className='h-auto sm:h-[200px] max-w-[280px] flex flex-wrap sm:flex-nowrap justify-around items-center mb-8 xl:my-0' key={index}>
									<img 
										id="l-img" 
										data-src="" 
										src={img.images} 
										className={`mt-8 ${(index === 0 || index === sectors[1].length-1) ? '' : 'sm:mx-4'} h-full`} alt="" title=""
									/>
								</li>
							)
						}
					)}
				</ul>
			</div>
			<div className='w-full lg:w-auto mt-2 xl:mt-12 flex flex-col justify-end items-center'>
				{sectors[0].map((sector, index) => (
						<h3 className='font-bold text-[18px] sm:text-[20px]' key={index} style={{color: `${sector.color}`}}>
							{sector.title}
						</h3>
					))
				}
			</div>
		</div>
	)
}

export default SectorTabs;