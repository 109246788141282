import React, { useState } from 'react';
import MobileMenu from '../SubComponents/MobileMenu';
import darkLogo from '../Assets/images/EQUITRAX-LOGO.png';
import lightLogo from '../Assets/images/EQUITRAX LOGO WHITE.png';
import { Button } from '@mui/material';
import { Link } from 'react-scroll';

const Navbar = () => {

	const [ThemeBtn, setThemeBtn] = useState(true);
	const [changeNavBg, setchangeNavBg] = useState(false);

	window.onscroll = function() {
		if (document.body.scrollTop >= 20 || document.documentElement.scrollTop >= 20) {
			setchangeNavBg(true);
		} else {
			setchangeNavBg(false);
		}
	};

	return (
		<nav className={`fixed left-0 top-0 z-[99999] w-screen nav-dark transition-all duration-900 ${changeNavBg? 'bg-white' : 'bg-transparent'} text-right`}>
			<div className="h-full flex justify-between items-center container py-[0.5rem] sm:py-[1rem] !max-w-[90%]">
				<a id="nav-sc-logo-link" className="nav-home-link h-full z-[99999] flex justify-center items-center" href="http://www.equitraxventures.com/">
					<img className={`nav-logo dark w-auto h-[1.2rem] sm:h-[2rem] z-[99999]  ${changeNavBg? 'block' : 'hidden'}`} src={darkLogo} alt="EQUITRAX CORPORATE VENTURES PRIVATE LIMITED" />
					<img className={`nav-logo light w-auto h-[1.2rem] sm:h-[2rem] z-[99999]  ${changeNavBg? 'hidden' : 'block'}`} src={lightLogo} alt="equitrax" />
				</a>
				<div className='flex justify-center items-center'>
					<ul className={`nav-menu list-none text-[1rem] text-right ${changeNavBg? 'text-black' : 'text-white'} text-white hidden sm:block md:block lg:block`}>
						<li className="nav-menu-item nav-cta hide-tablet-and-mobile inline-block leading-[2.5rem] text-[#fec113]">
							<Link className="nav-link" role="button" to="contact" smooth={true} duration={400}>
								<Button className='capitalize' variant="contained">Talk to Us</Button>
							</Link>
						</li>
					</ul>
					<MobileMenu ThemeBtn={ThemeBtn} changeNavBg = {changeNavBg} setThemeBtn={setThemeBtn} />
				</div>
			</div>
		</nav>
	)
}

export default Navbar;