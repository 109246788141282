import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Career = () => {

	const Career = [
		{
			title: 'Interns',
			content: `Looking to do coffee runs and redundant paperwork, or just sit around gawking at your cell phone? Please don’t apply. We are looking for interns who join our company as something more than just a learning experience. We provide them with opportunities to work on real-life projects and hone relevant skills. 
			\n Any creative, fun-loving, passionate and open-minded person who knows he/she is going to be the part of the team, is our fit.`
		},
		{
			title: 'Graduates or Early Career',
			content: `Let us be your initiators when you step into the professional world. We are looking for graduates who are seeking an early career opportunity and maybe if performed well, can be absorbed in the core team. 
			\n Any graduate who is keen to learn and perform at the same time, is thriving to be better in his/her field and is a team player, talk to us.`
		}
	]

	const executives = {

		title: 'Executives',
		persons: [
			{
				title: 'Human Dynamo',
				content: `A Human Dynamo is a person who is a go-getter, not only are they very enterprising in nature but they believe in smart work. An enthusiast who loves to communicate and help their clients to optimize their experience with the products. They help business see exponential growth. Think you are a Human Dynamo, contact us?`
			},
			{
				title: 'Engineers',
				content: `Our engineers bring solutions to life, settle for nothing less, and achieve their goals, no matter what. With unimaginable work-ethic, they provide tangible solutions to the problems and get their work noticed too.`
			},
			{
				title: 'Creatives',
				content: `They paint our venture and communicate our endeavors that go behind the curtain. These people are the designers, the makeup artists of how our company looks like. Care to stroke a brush?`
			}
		]
	}

	return (
		<div className='min-h-[60vh] flex flex-wrap flex-col md:flex-nowrap justify-between items-center container md:max-w-[70vw] py-[4rem] px-[1rem] sm:py-[6rem] sm:px-[1rem] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
			<h1 className='text-[1.4rem] md:text-[1.5rem] lg:text-[2rem] font-semibold mb-5 text-left sm:text-center'>
				#JoinOurGrowingCompany
			</h1>
			<div className='md:py-[1rem] lg:py-[0rem] w-full h-auto'>
				<h1 className='text-[1.4rem] md:text-[1.5rem]  text-justify md:text-center whitespace-pre-line'>
					They are the pillars of our company and the bedrock of our vision, <span className='font-semibold italic text-[#8f8c8b]'>interdependent</span> yet <span className='font-semibold italic text-[#8f8c8b]'>independent</span>.
					<br /> Each team member constantly endeavors to be the better version of themselves and the best version of the company.
				</h1>
				<div className='flex flex-wrap xl:flex-nowrap justify-center items-start mt-4'>
					{Career.map((item, i) => (
						<div className={`w-full ${i === 1 ? 'xl:ml-10 my-8 md:my-0' : '' }`} key={i}>
							<h1 className='text-[20px] text-left md:text-center font-semibold'>{item.title}</h1>
							<p className='leading-normal text-[16px] sm:text-[18px] text-justify mt-2 text-base whitespace-pre-line'>
								{item.content}
								<Button className='capitalize !font-bold' size="small" variant="text" href="mailto:careers@equitrax.tech" startIcon={<ArrowForwardIcon fontSize='small' />}>Apply</Button>
							</p>
						</div>
					))}
				</div>
				<div className='mt-4'>
					<h1 className='text-xl text-left md:text-center font-semibold'>{executives.title}</h1>
					<div className='flex flex-wrap xl:flex-nowrap justify-center items-start mt-2'>
						{executives.persons.map((item, i) => (
							<div className={`w-full ${i === 1 ? 'xl:mx-10 my-8 md:my-0' : '' }`} key={i}>
								<p className='leading-normal text-[16px] sm:text-[18px] text-justify mt-2 text-base whitespace-pre-line'>{
									(
										<span className={`leading-relaxed`}><strong>{item.title}</strong>: {item.content}</span>
									)
								}
								<Button className='capitalize !font-bold' size="small" variant="text" href="mailto:careers@equitrax.tech" startIcon={<ArrowForwardIcon fontSize='small' />}>Apply</Button>
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Career;