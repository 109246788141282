import React from 'react';
import video from '../Assets/videos/fInal video.mp4';

const HeaderBgVideo = () => {
	return (
		<div className='bg-video'>
			<video className="hide-mobile-only absolute top-0 left-0 h-full w-screen bg-cover object-cover" autoPlay="autoplay" loop="loop" muted playsInline>
				<source className='h-full w-full' src={video} type="video/mp4" />
			</video>
			<span className="absolute top-0 left-0 z-[99] bg-[#393736] h-full w-screen opacity-50"></span>
		</div>
	)
}

export default HeaderBgVideo;