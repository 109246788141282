import React from 'react';
import { Gmail, LinkedIn } from '../Assets/icons/Icons';
import lightLogo from '../Assets/images/EQUITRAX LOGO WHITE.png';
import { Link } from 'react-scroll';

const Footer = () => {
	return (
		<div className='bg-[#2f2c2b] flex justify-between items-center py-2 pr-[2rem] pl-[1rem]'>
			<div className='container flex flex-col sm:flex-row justify-between items-center'>
				<Link className="nav-link" role="button" to="home" smooth={true} duration={500}>
					<img className={`nav-logo light my-2 sm:my-0 w-auto h-[1.2rem] sm:h-[1.4rem] z-[99999] cursor-pointer`} to={{pathname:"/"}} src={lightLogo} alt="equitrax" />
				</Link>
				<p className="footer-copyright sm:py-0 inline text-center text-[#7a7776] text-[14px]">© 2020 <span className="text-[14px] tracking-[1.5px] ">All Rights Reserved</span></p>
				<ul className='flex list-none py-2 sm:py-0'>
					<li className='ml-4' ><a className='cursor-pointer' href="mailto:hello@equitraxventures.com"><Gmail className="fill-blue-500"  width={20} height={20} fill='#fff'/></a></li>
					{/* <li className='ml-4' ><a className='cursor-pointer'  href=''><Twitter className="fill-blue-500"  width={20} height={20} fill='#fff'/></a></li> */}
					<li className='ml-4' ><a className='cursor-pointer' target={'_blank'} rel="noreferrer" href="https://www.linkedin.com/company/equitrax/"><LinkedIn className="fill-blue-500" width={20} height={20} fill='#fff'/></a></li>
				</ul>
			</div>
		</div>
	)
}

export default Footer;