import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Link } from 'react-scroll';

const Toggler = styled.div`
	z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	padding: 7.5px;
	> span {
		width: 20px;
		height: 2px;
		margin-bottom: 0.28rem;
		transition: all 0.3s ease-in-out;
		border-radius: 10px;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		-ms-border-radius: 10px;
		-o-border-radius: 10px;
	}
	> span:last-child {
		margin: 0;
	}
	&.active span:first-child {
		transform: translateY(6.4px) rotate(45deg);
		-webkit-transform: translateY(6.4px) rotate(45deg);
		-moz-transform: translateY(6.4px) rotate(45deg);
		-ms-transform: translateY(6.4px) rotate(45deg);
		-o-transform: translateY(6.4px) rotate(45deg);
	}
	&.active span:nth-of-type(2) {
    	width: 0;
	}
	&.active span:last-child {
		transform: translateY(-6.4px) rotate(-45deg);
		-webkit-transform: translateY(-6.4px) rotate(-45deg);
		-moz-transform: translateY(-6.4px) rotate(-45deg);
		-ms-transform: translateY(-6.4px) rotate(-45deg);
		-o-transform: translateY(-6.4px) rotate(-45deg);
	}
`
const UnorderedList = styled.ul`
	position: absolute;
	top: 0;
	left: 0;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    list-style: none;
	transform: rotate(90deg);
	&.active {
		transition: all 1s ease;
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
	}
`
const Item = styled.li`
	position: absolute;
	top: 0px;
	right: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #000;
	border-radius: 50%;
	transition: all 0.3s ease-out;
`

const MobileMenu = (props) => {

	return (
		<div className={`relative flex items-center justify-center bg-transparent sm:hidden`} >
			<Toggler onClick={() => props.setThemeBtn(!props.ThemeBtn)} className={`${props.ThemeBtn ? '': 'active'} z-[99999]`}>
				<span className={`${props.changeNavBg? 'bg-black' : 'bg-white'}`}></span>
				<span className={`${props.changeNavBg? 'bg-black' : 'bg-white'}`}></span>
				<span className={`${props.changeNavBg? 'bg-black' : 'bg-white'}`}></span>
			</Toggler>
			<ul className={`${props.ThemeBtn ? 'h-0': 'h-auto p-4 pb-6'} rounded-md w-[94%] m-auto text-center flex flex-col justify-between items-end fixed transition-all duration-900 left-1/2 -translate-x-1/2 top-[46px] bg-zinc-900/70 z-[99]`}>
				<li className={`${props.ThemeBtn ? 'hidden': 'block'} w-full nav-menu-item nav-cta hide-tablet-and-mobile leading-[2.5rem]`}>
					<Link className="nav-link" role="button" to="contact" smooth={true} duration={400}>
						<Button className='capitalize !px-7' variant="contained">Talk to Us</Button>
					</Link>
				</li>
			</ul>
		</div>
	)
}

export default MobileMenu;