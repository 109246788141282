import './App.css';
import AboutSection1 from './Components/AboutSection1';
// import AboutSection2 from './Components/AboutSection2';
import AboutSection3 from './Components/AboutSection3';
// import AboutSection4 from './Components/AboutSection4';
import Achievement from './Components/Achievement';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
import Sectors from './Components/Sectors';
import Career from './Components/Career';

function App() {

	return (
		<div className=''>
			<Navbar />
			<Header />
			<AboutSection1 />
			{/* <AboutSection2 /> */}
			<AboutSection3 />
			<Achievement />
			<Sectors />
			<Career />
			{/* <AboutSection4 /> */}
			<Contact />
			<Footer />
		</div>
	);
}

export default App;
