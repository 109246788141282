import React, {useState} from 'react';
import InputField from "./CustomInputField";
import { Button, Paper } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import axios from 'axios';

const CustomForm = ({ status, message, onValidated }) => {
	const [formData, setFormData] = useState({
		email : '',
		firstName : '',
		lastName : '',
		company : '',
		phoneNumber : '',
		sendMessage : '',
		customStatus : '',
	});
	
	const clearFields = () => {
		setFormData({
			email : '',
			firstName : '',
			lastName : '',
			company : '',
			phoneNumber : '',
			sendMessage : '',
			customStatus : '',
		})
	}

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
        e.preventDefault();
		if (
		formData.email &&
        formData.firstName &&
        formData.lastName &&
        formData.email.indexOf("@") > -1 &&
		formData.company &&
		formData.phoneNumber &&
		formData.sendMessage ) {
			try {
			  await axios.post('https://mail.equitrax.tech/send-equitrax-email', formData);
			  setFormData({...formData, customStatus: 'success'});
			} catch (error) {
			  console.error('Error sending email:', error);
			  alert('Error sending email. Please try again later.');
			}
		}
    }

    return (
		<Paper className='w-full flex items-end justify-center h-[660px] flex-col p-4 sm:p-12 text-[#4e4b4a]' elevation={0}>

			{formData.customStatus !== "success" ? (
				<h1 className='text-[1.4rem] text-[#4e4b4a] md:text-[1.5rem] lg:text-[2rem] font-semibold mb-0 sm:mb-5 text-left'>
					Connect with us to re-target your customers with competitive intelligence.
				</h1>) : ''
			}
			
			{formData.customStatus !== "success" ? (
				<form className="flex flex-col justify-center items-end w-full" onSubmit={(e) => handleSubmit(e)}>
						<InputField
							label="First Name"
							name = "firstName"
							onChangeHandler={handleChange}
							type="text"
							error = {/^[A-Za-z\s]*$/.test(formData.firstName) ? false : true}
							value={formData.firstName}
							placeholder="Jane"
							helperText = {
								/^[A-Za-z\s]*$/.test(formData.firstName) ? '' : "Please enter a valid First Name"
							}
							isRequired
						/>
						<InputField
							label="Last Name"
							name = "lastName"
							onChangeHandler={handleChange}
							type="text"
							error = {/^[A-Za-z\s]*$/.test(formData.lastName) ? false : true}
							value={formData.lastName}
							placeholder="Doe"
							helperText = {
								/^[A-Za-z\s]*$/.test(formData.lastName) ? '' : "Please enter a valid Last Name"
							}
							isRequired
						/>
						<InputField
							label="Email ID"
							name="email"
							onChangeHandler={handleChange}
							type="email"
							error = {
								formData.email ? (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(formData.email) ? false : true) : false 
								}
							value={formData.email}
							placeholder="abc@company.com"
							helperText = {
								formData.email ? (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(formData.email) ? '' : 'Please enter a valid email address') : ''
							}
							isRequired
						/>
						<InputField
							label="Company"
							name="company"
							onChangeHandler={handleChange}
							type="text"
							error = {
								formData.company ? (/^[a-z0-9\s]*$/i.test(formData.company) ? false : true) : false
							}
							value={formData.company}
							placeholder="Your Company Name"
							helperText = {
								formData.company ? (/^[a-z0-9\s]*$/i.test(formData.company) ? '' : 'Please Enter a valid Company name') : ''
							}
							isRequired
						/>
						<InputField
							label="Phone Number"
							name="phoneNumber"
							onChangeHandler={handleChange}
							type="number"
							error = {
								formData.phoneNumber ? (/^\d+$/.test(formData.phoneNumber) ? false : true) : false
							}
							value={formData.phoneNumber}
							placeholder="9810098100"
							helperText = {
								formData.phoneNumber ? (/^\d+$/.test(formData.phoneNumber) ? '' : 'Please Enter a valid Phone Number') : ''
							}
							isRequired
						/>
						<InputField
							label="How can we help you ?"
							name="sendMessage"
							onChangeHandler={handleChange}
							type="textarea"
							value={formData.sendMessage}
							error = {/^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 .,!?-_'"#@&$]*)?$/i.test(formData.sendMessage) ? false : true}
							placeholder="Write Something"
							helperText = {/^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 .,!?-_'"#@&$]*)?$/i.test(formData.sendMessage) ? '' : 'Characters are not allowed in message box'}
							isRequired
						/>
						<InputField
							label="subscribe"
							type="submit"
							formValues={[	
									formData.email, 
									formData.firstName, 
									formData.lastName, 
									formData.company, 
									formData.phoneNumber, 
									formData.sendMessage
								]}
						/>
				</form>
			) : 
				<div className='w-full p-12'>
					{formData.customStatus === "sending" && (
						<div className='w-full flex items-center justify-center flex-col'>
							<h1 className="text-[40px] text-blue-600">
								sending...
							</h1>
							<Button
								variant="contained"
								endIcon={<ThumbUpIcon />}
								onClick={() => clearFields()}
								label="close"
								className='!mt-6'
							>
								Okay
							</Button>
						</div>
					)}
					{formData.customStatus === "error" && (
						<div className='w-full flex items-center justify-center flex-col'>
							<h1 
								className="text-[40px] text-red-600"
								dangerouslySetInnerHTML={{ __html: message }}
							/>
							<Button
								variant="contained"
								endIcon={<ThumbUpIcon />}
								onClick={() => clearFields()}
								label="close"
								className='!mt-6'
							>
								Okay
							</Button>
						</div>
					)}
					{formData.customStatus === "success" && (
						<div className='w-full flex items-center justify-center flex-col'>
							<h1 className="text-[30px] text-center text-green-600">
								Thank you for writing. We will get in touch with you shortly.
							</h1>
							<Button
								variant="contained"
								endIcon={<ThumbUpIcon />}
								onClick={() => clearFields()}
								label="close"
								className='!mt-6'
							>
								Okay
							</Button>
						</div>
					)}
				</div>
			}
		</Paper>
	);
};

export default CustomForm;