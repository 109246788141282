import React from 'react';
import sectionImage from '../Assets/images/about-section.gif'

const AboutSection1 = () => {
	return (
		<div name='about-section-1' className='min-h-[60vh] flex flex-wrap lg:flex-nowrap justify-between items-center container py-[4rem] px-[1rem] sm:py-[6rem] sm:px-[2rem] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
			<div className='w-full flex items-center justify-center flex-col self-stretch'>
				{/* <iframe className='w-full max-w-[600px] h-[220px] sm:h-[320px]'
					src="https://www.youtube.com/embed/C6WKt6fJiso"
					title="YouTube video player" 
					frameBorder="0" 
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
					allowFullScreen
				></iframe> */}
				<img id="l-img" 
					data-src="" 
					src={sectionImage} 
					className="w-full max-w-full block" alt="section emerging technologies" title="" 
				/>
			</div>
			<div className='lg:pl-[4rem] py-[2rem] md:py-[1rem] lg:py-[0rem] w-full'>
				<h1 className='text-[1.4rem] md:text-[1.5rem] lg:text-[2rem] leading-snug font-semibold mb-4'>Emerging technologies and cultural shifts are radically transforming customer expectations.</h1>
				<p className='leading-normal mb-3 text-[16px] sm:text-[18px]'>
					Each year over two trillion dollars of profits are lost and over half of the world’s revenue waste is generated from how we manufacture and distribute goods.
				</p>
				<p className='leading-normal mb-3 text-[16px] sm:text-[18px]'>
				We are a tech innovations company building a Deep Tech Cloud LifeCycle Management & Optimisation Platform that cuts through the clutter of data and delivers exceptional business results.
				</p>
				<p className='leading-normal mb-3 text-[16px] sm:text-[18px]'>
				At EQUITRAX, we thrive to disrupt the Industry's status quo by never going out of style. Our belief is to create solutions that break the structures and fulfill the very basic needs of people. We here take upon the most complex challenges that can create an impact and difference to humans.
				</p>
			</div>
		</div>
	)
}

export default AboutSection1;